import { cryptoApi } from 'api/crypto.api';
import { AppActions } from 'store';

import { call, put } from 'redux-saga/effects';

export function* getCryptosSaga(action: any) {
  try {
    const { cryptos, offRampCryptos } = yield call(
      async () => await cryptoApi.getCryptos()
    );
    yield put(AppActions.crypto.getCryptosSuccess({ cryptos, offRampCryptos }));
  } catch (error: any) {
    if (error.response)
      yield put(AppActions.crypto.getCryptosFailure(error.response.data));
  }
}

export function* checkTravelRulesSaga(action: any) {
  try {
    const { cryptos } = yield call(cryptoApi.checkTravelRules, action.payload);
    yield put(AppActions.crypto.checkTravelRulesSuccess(cryptos));
  } catch (error: any) {
    if (error.response)
      yield put(AppActions.crypto.checkTravelRulesFailure(error.response.data));
  }
}
