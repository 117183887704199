import { RAMPING, USER_KYC_STATUS, UserType } from 'utils/consts';

export interface ICrypto {
  id: number;
  name: string;
  shortName: string;
  ticker: string;
  chain: string;
}

export interface ICurrency {
  code: string;
  symbol: string;
  name: string;
}

export interface ICountry {
  name: string;
  code: string;
  fullName?: string;
  phoneCode?: string;
  language?: string;
  flag?: any;
}

export interface AuthenticationInfo {
  emailAddress?: string;
  phoneCode?: string;
  phoneNumber?: string;
  businessName?: string;
  saasUserCorporationId?: number;
  merchantId?: number;
  userType?: UserType;
  referralCode?: string;
}

export interface IUser {
  id: number;
  customerId?: number;
  kycStatus: USER_KYC_STATUS;
  kycVeriffUuid: string;
  role?: number;
  type?: number;
  createdAt?: string;
  activated?: number;
  email?: string;
  countryCode?: string;
  phoneNumber?: string;
  avatar?: string;
  firstName?: string;
  lastName?: string;
  birthday?: string;
}

export interface IBusinessMemeber {
  id: number;
  business_corporation_id: number;
  kycStatus: USER_KYC_STATUS;
  kycVeriffUuid: string;
}

export interface IOrder {
  id: string;
  type: RAMPING;
  currency: string;
  currencySymbol: string;
  fiatAmount: number;
  crypto: string;
  cryptoAmount: number;
  orderUuid: string;
  createdTime: string;
  liquidityQuote: number;
  liquidityProvider: string;
  processingFee: number;
  status: number;
  walletAddress?: string;
  transactionHash?: string;
  blockchairNetworkName?: string;
}

export interface IQuote {
  liquidityProvider: string;
  liquidityQuotes: number;
  subscriptionFee: number;
}

export interface INuveiSessionToken {
  internalRequestId: number;
  status: 'SUCCESS' | 'ERROR';
  errCode: number;
  reason: string;
  version: string;
  orderId: number;
  sessionToken: string;
  merchantSiteId: string;
  merchantId: string;
}

export interface IFee {
  fee: number;
}

export interface IDocumentType {
  code: string;
  description: string;
  status: boolean;
}

export interface IBusinessDetail {
  businessName: string;
  businessNo?: string;
  businessAddress?: string;
  country?: string;
  industry?: string;
  sideIndustry?: string;
  registrationDate?: string;
  registrationAddress?: string;
  supplierName?: string;
  supplierContracts?: string;
  website?: string;
  turnoverYear?: string;
  turnoverWeek?: string;
  shareholdingStructure?: string;
  email?: string;
  countryCode?: string;
  phoneNumber?: string;
  status?: number;
  userKycStatus?: number;
  riskLevel?: string;
  kycStatus?: number;
  businessDesc?: string;
}

export interface BusinessMember {
  id: string;
  createdAt: string;
  businessCustomerCorporationId: number;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  countryCode: string;
  phoneNumber: string;
  address: string;
  city: string;
  postCode: string;
  country: string;
  memberType: number;
  birthday: string;
  nationality: string;
  documentType: string;
  documentNo: string;
  documentFrontUrl: string;
  documentBackUrl: string;
  documentHandyUrl: string;
  kycVeriffUuid: string;
  kycStatus: number;
}
export interface ISaaSUserCorporation {
  id: number;
  code: string;
  name: string;
  domain: string;
  themeUrl: string;
}

/**
 * 1 - OTC On-ramp
 * 2 - OTC Off-ramp
 * 3 - Retail On-ramp
 * 4 - Retail Off-ramp
 * 5 - Retail NFT-checkout
 */
export enum TransactionTypeForFee {
  OTC_ON_RAMP = 1,
  OTC_OFF_RAMP,
  RETAIL_ON_RAMP,
  RETAIL_OFF_RAMP,
  RETAIL_NFT_CHECKOUT,
}
export interface ICard {
  cardNumber: number;
  cardType: string;
  id: string;
}
