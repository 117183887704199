import { CancelSvg } from "utils/icons";
import { SelectCrypto } from "../selects";

interface IProps {
  crypto: string;
  type: number;
  onChange: (crypto: string) => void;
  onClose: () => void;
}

const SelectCryptoPopup: React.FC<IProps> = (props) => {
  const { crypto, type, onChange, onClose } = props;

  return (
    <div className="flex flex-col fixed left-0 top-0 w-full h-screen z-50 bg-surface-primary p-4">
      <div className="flex justify-between items-end mb-4">
        <label className="text-lg font-bold text-font-primary">Select a Crypto</label>
        <img className="" src={CancelSvg} onClick={() => { onClose(); }} />
      </div>
      <SelectCrypto
        type={type}
        crypto={crypto}
        onChange={(crypto) => {
          onChange(crypto.shortName);
          onClose();
        }}
      />
    </div>
  );
};

export default SelectCryptoPopup;