import { createSlice } from '@reduxjs/toolkit';
import { INuveiSessionToken, IOrder } from 'types';

interface IInitialTransactionSlice {
  orders: IOrder[];
  uncompletedOrders: IOrder[];
  offset: number;
  limit: number;
  totalOrdersCount: number;
  currentOrder: IOrder | null;

  houseWalletAddress: string;
  orderStatus: string;

  creatingOrder: boolean;
  createdOrder: boolean;
  createdOrderId: string;

  validatingCard: boolean;
  validatedCard: boolean;
  validatedCardTokenId: string;

  gettingOrder: boolean;
  gotOrder: boolean;

  updatingOrder: boolean;
  updatedOrder: boolean;

  gettingOrders: boolean;
  gotOrders: boolean;

  gettingUncompletedOrders: boolean;
  gotUncompletedOrders: boolean;

  addInformationRequired: boolean;

  error: any;
  nuveiSessionToken: INuveiSessionToken;
}

const orderSlice = createSlice({
  name: 'order',
  initialState: {
    orders: [],
    uncompletedOrders: [],
    houseWalletAddress: '',
    orderStatus: '',
    creatingOrder: false,
    createdOrder: false,
    createdOrderId: '',
    validatingCard: false,
    validatedCard: false,
    validatedCardTokenId: '',
    nuveiSessionToken: {} as INuveiSessionToken,

    gettingOrder: false,
    gotOrder: false,

    currentOrder: null,

    updatingOrder: false,
    updatedOrder: false,

    gettingOrders: false,
    gotOrders: false,

    gettingUncompletedOrders: false,
    gotUncompletedOrders: false,

    offset: 0,
    limit: 10,
    totalOrdersCount: 0,

    addInformationRequired: false,

    error: '',
  } as IInitialTransactionSlice,
  reducers: {
    initOrderStatus(state, action) {
      state.error = '';
      state.createdOrderId = '';
      state.currentOrder = null;
      state.creatingOrder = false;
    },

    createOrder(state, action) {
      state.creatingOrder = true;
      state.createdOrder = false;
      state.updatingOrder = false;
      state.updatedOrder = false;
      state.addInformationRequired = false;
    },
    createOrderSuccess(state, action) {
      state.creatingOrder = false;
      state.createdOrder = true;
      const { order, status, houseWalletAddress } = action.payload;
      state.orders = [...state.orders, order];
      state.houseWalletAddress = houseWalletAddress || '';
      state.orderStatus = status;

      state.currentOrder = order;
    },
    createOrderFailure(state, action) {
      state.creatingOrder = false;
      state.createdOrder = false;

      const { status } = action.payload;
      if (status == 'TRAVEL_RULE_ADDITIONAL_INFORMATION') {
        state.addInformationRequired = true;
      }
      state.error = action.payload;
    },

    createOffRampOrder(state, action) {
      state.creatingOrder = true;
      state.createdOrder = false;
    },

    updateOrderStatus(state, action) {
      const { orderId, status } = action.payload;
      const index = state.orders.findIndex((el) => el.id === orderId);
      state.orders[index].status = status;
      state.createdOrder = false;
    },
    openOrderRequest(state, action) { },
    openOrderRequestSuccess(state, action) {
      state.nuveiSessionToken = action.payload;
    },
    openOrderRequestFailure() { },

    createPaymentRequest(state, action) {
      state.updatingOrder = true;
      state.updatedOrder = false;
    },
    createPaymentRequestSuccess(state, action) {
      state.updatingOrder = false;
      state.updatedOrder = true;
      const { order } = action.payload;
      state.currentOrder = order;
    },
    createPaymentRequestFailure(state, action) {
      state.updatingOrder = false;
      state.updatedOrder = false;
      state.error = action.payload;
    },

    updateOrder(state, action) {
      state.updatingOrder = true;
      state.updatedOrder = false;
    },

    updateOrderSuccess(state, action) {
      state.updatingOrder = false;
      state.updatedOrder = true;
      const { order, nuveiSessionToken } = action.payload;
      state.currentOrder = order;
      state.nuveiSessionToken = nuveiSessionToken
        ? nuveiSessionToken
        : state.nuveiSessionToken;
    },

    updateOrderFailure(state, action) {
      state.updatingOrder = false;
      state.updatedOrder = false;
      state.error = action.payload;
    },

    getOrders(state, action) {
      state.gettingOrders = true;
      state.gotOrders = false;
    },

    getOrdersSuccess(state, action) {
      state.gettingOrders = false;
      state.gotOrders = true;

      const { orders, totalOrdersCount, offset, limit } = action.payload;
      state.orders = [...orders];
      state.totalOrdersCount = totalOrdersCount;
      state.offset = offset;
      state.limit = limit;
    },

    getOrdersFailure(state, action) {
      state.gettingOrders = false;
      state.gotOrders = false;
      state.error = action.payload;
    },

    getUncompletedOrders(state, action) {
      state.gettingOrders = true;
      state.gotOrders = false;
    },

    getUncompletedOrdersSuccess(state, action) {
      state.gettingOrders = false;
      state.gotOrders = true;

      const { orders } = action.payload;
      state.uncompletedOrders = [...orders];
    },

    getUncompletedOrdersFailure(state, action) {
      state.gettingOrders = false;
      state.gotOrders = false;
      state.error = action.payload;
    },

    getOrder(state, action) {
      state.gettingOrder = true;
      state.gotOrder = false;
      state.currentOrder = null;
      state.houseWalletAddress = '';
    },

    getOrderSuccess(state, action) {
      state.gettingOrder = true;
      state.gotOrder = false;
      const { order, houseWalletAddress, transactionHash, blockchairNetworkName } = action.payload;
      state.currentOrder = {
        ...order,
        transactionHash,
        blockchairNetworkName
      };
      state.houseWalletAddress = houseWalletAddress || '';
    },

    getOrderFailure(state, action) {
      state.gettingOrder = true;
      state.gotOrder = false;
      state.error = action.payload;
    },

    createPaymentCheckout(state, action) {
      state.creatingOrder = true;
    },

    createPaymentCheckoutSuccess(state, action) {
      state.creatingOrder = false;
      state.createdOrder = true;
    },

    createPaymentCheckoutFailure(state, action) {
      state.creatingOrder = false;
      state.error = action.payload;
    },

    createPayoutCheckout(state, action) {
      state.creatingOrder = true;
    },

    createPayoutCheckoutSuccess(state, action) {
      state.creatingOrder = false;
      state.createdOrder = true;
    },

    createPayoutCheckoutFailure(state, action) {
      state.creatingOrder = false;
      state.error = action.payload;
    },

    validateNewCard(state, action) {
      state.validatingCard = true;
    },

    validateNewCardSuccess(state, action) {
      state.validatingCard = false;
      state.validatedCard = true;
      state.validatedCardTokenId = action.payload.tokenId;
    },

    validateNewCardFailure(state, action) {
      state.validatingCard = false;
      state.error = action.payload;
    },

    resetOrderStates(state) {
      state.error = '';
    },
  },
});

export const actions = orderSlice.actions;
export const reducer = orderSlice.reducer;
