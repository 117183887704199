import { createSlice } from '@reduxjs/toolkit';
import { ICrypto } from 'types';

interface IInitialCryptoSlice {
  cryptos: ICrypto[];
  offRampCryptos: ICrypto[];
  amount: number;
  gettingCryptos: boolean;
  gotCryptos: boolean;

  checkingTravelRules: boolean;
  checkedTravelRules: boolean;
  error: any;
}

const cryptoSlice = createSlice({
  name: 'quote',
  initialState: {
    cryptos: [],
    offRampCryptos: [],
    gettingCryptos: false,
    gotCryptos: false,

    checkingTravelRules: false,
    checkedTravelRules: false,

    amount: 0, // default amount
    error: '',
  } as IInitialCryptoSlice,
  reducers: {
    setAmount(state, action) {
      state.amount = action.payload;
    },

    getCryptos(state, action) {
      state.gettingCryptos = true;
      state.gotCryptos = false;
    },
    getCryptosSuccess(state, action) {
      state.gettingCryptos = false;
      state.gotCryptos = true;

      state.cryptos = [...action.payload.cryptos];
      state.offRampCryptos = [...action.payload.offRampCryptos];
    },
    getCryptosFailure(state, action) {
      state.gettingCryptos = false;
      state.gotCryptos = false;
      state.error = action.payload;
    },

    // check travel rules
    checkTravelRules(state, action) {
      state.checkingTravelRules = true;
      state.checkedTravelRules = false;
    },
    checkTravelRulesSuccess(state, action) {
      state.checkingTravelRules = true;
      state.checkedTravelRules = false;
    },
    checkTravelRulesFailure(state, action) {
      state.checkingTravelRules = false;
      state.checkedTravelRules = false;
      state.error = action.payload;
    },
  },
});

export const actions = cryptoSlice.actions;
export const reducer = cryptoSlice.reducer;
