import { Route, Routes, useLocation, Navigate, useNavigate } from "react-router-dom";
import Register from 'pages/authentication/Register';
import Login from 'pages/authentication/Login';
import ForgotPassword from 'pages/authentication/ForgotPassword';
import { Buy } from 'pages/buy';
import ResetPassword from 'pages/authentication/ResetPassword';
import { AuthRoute } from 'components/authentication';
import { Settings } from 'pages/settings';
import { Messages } from 'pages/messages';
import { PATHS, RAMPING, merchantId, saasUserCorporationId } from 'utils/consts';
import { Sell } from 'pages/sell';
import Order from 'pages/Order';
import { PlatformContext } from 'contexts/platformContext';
import { Landing, History } from './pages';
import './App.css';
import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppActions } from "store";
import { VeriffAfterAuth, VeriffFromInvitationLink } from "components/authentication/veriff";
import { BusinessPage } from "pages/business";
import { IUser } from "types";
import CryptoDeposit from "pages/sell/CryptoDeposit";

const App: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { saveToken, saveUser, resetSession } = useContext(PlatformContext);

  const messageListener = (event: MessageEvent<any>) => {
    const { data } = event;
    const {
      sso_customer_id: ssoCustomerId,
      sso_hash: ssoHash,
      email_address: emailAddress,
      customer_first_name: firstName,
      customer_surname: lastName,
      dob,
      low_volume_indicator: lowVolumeIndicator,
      terms_of_use_indicator: termsOfUseIndicator,
      ip_address: ipAddress,
    } = data;

    if (ssoCustomerId) {
      dispatch(AppActions.user.ssoLogin({
        merchantId,
        ssoCustomerId,
        ssoHash,
        emailAddress,
        firstName,
        lastName,
        dob,
        lowVolumeIndicator,
        termsOfUseIndicator,
        saasUserCorporationId,
        ipAddress,
        next: (token: string, user: IUser) => {
          saveToken(token);
          saveUser(user);
        },
        goToRegister: () => {
          navigate(PATHS.REGISTER);
        },
        error: () => {
          console.log("error");
          resetSession();
        }
      }));
    }
  };

  useEffect(() => {
    dispatch(AppActions.crypto.getCryptos({
      saasUserCorporationId
    }));
    dispatch(AppActions.currency.getCurrencies({
      saasUserCorporationId
    }));
    dispatch(AppActions.country.getCountry({
      saasUserCorporationId
    }));

    window.addEventListener("message", messageListener);

    return () => {
      window.removeEventListener("message", messageListener);
    };
  }, []);

  return (
    <div className="flex flex-col h-screen">
      <Routes location={location}>
        <Route path={PATHS.BUY} element={<Landing tab={RAMPING.ON} />} />
        <Route path={`${PATHS.BUY}/*`} element={<AuthRoute><Buy /></AuthRoute>} />
        <Route path={PATHS.SELL} element={<Landing tab={RAMPING.OFF} />} />
        <Route path={`${PATHS.SELL}/*`} element={<AuthRoute><Sell /></AuthRoute>} />
        <Route path={`${PATHS.SETTINGS}/*`} element={<Settings />} />
        <Route path={`${PATHS.MESSAGES}/*`} element={<Messages />} />
        <Route path={PATHS.LOGIN} element={<Login />} />
        <Route path={PATHS.HISTORY} element={<AuthRoute><History /></AuthRoute>} />
        <Route path={`${PATHS.HISTORY}/:id`} element={<AuthRoute><Order /></AuthRoute>} />
        <Route path={`${PATHS.DEPOSIT}/:id`} element={<AuthRoute><CryptoDeposit /></AuthRoute>}></Route>
        <Route path={PATHS.VERIFF} element={<VeriffFromInvitationLink />} />
        <Route path={PATHS.KYC} element={<VeriffAfterAuth />} />
        <Route path={PATHS.REGISTER} element={<Register />} />
        <Route path={PATHS.RESETPASSWORD} element={<ResetPassword />} />
        <Route path={PATHS.FORGOTPASSWORD} element={<ForgotPassword />} />
        <Route path={`${PATHS.BUSINESS}/*`} element={<AuthRoute><BusinessPage /></AuthRoute>} />
        <Route path="*" element={<Navigate to={PATHS.BUY} />} />
      </Routes>
    </div>
  );
};

export default App;
