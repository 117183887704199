import { useEffect, useMemo, useState } from "react";

import { SearchInCircleSvg, SearchSvg } from "utils/icons";
import { ICrypto } from "types";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { cryptoSvgs } from "utils/consts";

interface IProps {
  className?: string,
  crypto: string,
  type: number,
  onChange?: (crypto: ICrypto) => void,
}

export const SelectCrypto: React.FC<IProps> = (props) => {
  const { className, crypto, type, onChange } = props;
  const { cryptos, offRampCryptos } = useSelector((root: RootState) => root.crypto);
  const [filter, setFilter] = useState<string>("");

  const onChangeFilter = (e: any) => {
    setFilter(e.target.value);
  };

  const onChangeCrypto = (crypto: ICrypto) => {
    onChange && onChange(crypto);
  };

  const _cryptos = useMemo(() => {
    if (type)
      return offRampCryptos.filter(crypto => ((crypto.name && crypto.name.includes(filter)) || crypto.chain.includes(filter) || crypto.shortName.includes(filter)));
    return cryptos.filter(crypto => ((crypto.name && crypto.name.includes(filter)) || crypto.chain.includes(filter) || crypto.shortName.includes(filter)));
  }, [filter]);

  return (
    <>
      <div className={`relative flex w-full ${className}`}>
        <img className="absolute left-4 top-4" src={SearchSvg} />
        <input
          className="block p-4 pl-12 w-full text-base font-medium text-font-primary bg-surface-secondary rounded-xl border border-surface-secondary outline-none focus:ring-primary focus:border-primary duration-200"
          onChange={onChangeFilter}
        />
      </div>
      <div className="overflow-auto flex-grow">
        {
          _cryptos.length ? _cryptos.map((el: any, index: number) => {
            return (
              <div
                key={index}
                className={`flex items-center mt-4 p-2 ${el.shortName === crypto && 'bg-feedback-information-tint'}`}
                onClick={() => { onChangeCrypto(el); }}
              >
                <div className="w-8 h-8 rounded-full bg-center bg-cover mr-4" style={{ backgroundImage: `url(${cryptoSvgs[el.shortName]})` }} />
                <div>
                  <div className="text-base font-medium text-font-primary">{el.name}</div>
                  <div className="text-sm font-medium text-gray-500">{el.shortName} • {el.chain}</div>
                </div>
              </div>
            );
          })
            : filter && (
              <div className="flex flex-col justify-content items-center p-12">
                <img src={SearchInCircleSvg} />
                <span className="text-base font-medium text-font-primary mt-4">We do not support this currency yet!</span>
              </div>
            )
        }
      </div>
    </>
  );
};