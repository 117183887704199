import Header from "components/common/header/Header";
import { useEffect, useState } from "react";
import OrderStatusComponent from "components/common/OrderStatus";
import { useDispatch, useSelector } from "react-redux";
import { AppActions, RootState } from "store";
import { RAMPING, ORDER_STATUS, orderStatus, PATHS } from "utils/consts";
import { useParams } from "react-router-dom";
import PendingIcon from "assets/icons/PendingIcon";

const Order: React.FC = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { currentOrder: order } = useSelector((state: RootState) => state.order);
  const [updateIn, setUpdateIn] = useState<number>(10);

  const okTransactionLink = `https://www.oklink.com/multi-search#key=${order?.transactionHash}`;
  const blockchairTransactionLink = `https://blockchair.com/${order?.blockchairNetworkName}/transaction/${order?.transactionHash}?from=wynpay`;

  useEffect(() => {
    dispatch(AppActions.order.getOrder({
      orderUuid: id,
    }));
  }, [id]);

  useEffect(() => {
    setTimeout(() => {
      setUpdateIn(updateIn => updateIn > 0 ? updateIn - 1 : 30);
    }, 1000);

    if (updateIn === 0) {
      if (order && orderStatus[order.status] == ORDER_STATUS.PROCESSING) {
        dispatch(AppActions.order.getOrder({
          orderUuid: id,
        }));
      }
    }
  }, [updateIn]);

  return (
    <>
      <Header
        backTo={PATHS.HISTORY}
        title={(order && orderStatus[order.status]) == ORDER_STATUS.DONE ? "Your Transaction is Complete" : (order ? "Main Menu" : "Loading...")}
      />
      <div className="p-4 mt-4">
        {
          order ?
            <>
              <h1 className="text-2xl font-bold text-font-primary">Order Status</h1>
              <OrderStatusComponent
                type={order.type ? RAMPING.OFF : RAMPING.ON}
                status={orderStatus[order.status] as ORDER_STATUS}
                orderedTime={new Date(order.createdTime).toLocaleString()}
                orderId={order.orderUuid}
                crypto={order.crypto}
              />
              {
                order.type ? 
                <p className="text-sm font-medium text-font-primary">Payment has been initiated to your selected Bank account and should arrived in 1-3 business days.</p> :
                <p className="text-sm font-medium text-font-primary">Funds will be sent to your wallet shortly and you will receive a notification</p>
              }
              <h5 className="text-base font-bold text-font-primary mt-8">Order Details</h5>
              <h6 className="text-sm font-medium text-font-subtle mt-4">Order ID</h6>
              <p className="text-sm font-medium text-font-primary mt-2 mb-4">{order.orderUuid}</p>
              {
                (orderStatus[order.status] === ORDER_STATUS.DONE || orderStatus[order.status] === ORDER_STATUS.FULLFILLED) &&
                <>
                  <h6 className="text-sm font-medium text-primary mt-4">You can view the transaction on the Blockchain at this</h6>
                  <h6><a className="text-blue-500 underline" href={okTransactionLink} target='_blank'>OK link</a></h6>
                  <h6><a className="text-blue-500 underline" href={blockchairTransactionLink} target='_blank'>Blockchair link</a></h6>
                </>
              }
            </> :
            <>
              <div className="w-full h-full flex items-center justify-center">
                <div className={`w-36 h-36 z-20 animate-spin-slow`} >
                  <PendingIcon className='fill-font-secondary w-36 h-36' />
                </div>
              </div>
            </>
        }
      </div>
    </>
  );
};

export default Order;