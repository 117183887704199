import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import QRCode from "react-qr-code";

import Header from "components/common/header/Header";
import CopyIcon from "assets/icons/CopyIcon";
import CheckIcon from "assets/icons/CheckIcon";
import { AppActions, RootState } from "store";
import { cryptoSvgs } from "utils/consts";
import { ellipsisAddress } from "utils/supports";

const CryptoDeposit: React.FC = () => {
  const { id } = useParams();
  const [copied, setCopied] = useState<boolean>(false);

  const dispatch = useDispatch();
  const { houseWalletAddress, currentOrder: order } = useSelector((state: RootState) => state.order);

  const onClickCopy = () => {
    navigator.clipboard.writeText(houseWalletAddress)
      .then(() => {
        setCopied(true);
      })
      .catch((error) => {
        setCopied(false);
      });
  }

  useEffect(() => {
    dispatch(AppActions.order.getOrder({
      orderUuid: id,
    }));
  }, [id]);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  const onClickBack = () => {
  }

  return (
    <>
      <Header title="Deposit Details" onClickBack={onClickBack} />
      {
        !!order &&
        <div className="p-4 mt-4">
          <h1 className="text-2xl font-bold text-font-primary mb-4">Deposit Instruction</h1>
          <p className="text-sm font-medium text-font-primary mb-8">Send {order.crypto} to the following deposit wallet address. Please check the order details carefully before sending, as we cannot retrieve {order.crypto} sent to the wrong address.</p>

          <span className="text-base font-bold text-font-primary">Please send <span className="text-base font-medium text-primary-light">{order.cryptoAmount} {order.crypto}</span> to the following address:</span>

          <div className="flex justify-between items-center bg-feedback-information-tint p-2 rounded-xl mt-2">
            <div className="flex items-center">
              <div className="w-5 h-5 rounded-full bg-center bg-cover mr-2" style={{ backgroundImage: `url(${cryptoSvgs[order.crypto]})` }} />
              <span className="text-base font-medium text-font-primary">{ellipsisAddress(houseWalletAddress)}</span>
            </div>
            {
              copied ?
                <CheckIcon className="duration-100" /> :
                <CopyIcon className="focus:w-5 focus:h-5 duration-100" onClick={onClickCopy} />
            }
          </div>

          <div className="flex justify-center mt-8">
            <QRCode className="w-48 h-48" value={houseWalletAddress} />
          </div>
          <p className="text-sm font-medium text-font-primary mt-8">Once we’ve received your {order.crypto}, we will send {order.currency} to your bank account. This can take a few hours.</p>
          <p className="text-sm font-medium text-font-primary mt-8">If you have any questions, <a href="mailto:customer-support@wynpay.io" className="text-primary">we’re here to help.</a></p>
        </div>
      }
    </>
  );
};

export default CryptoDeposit;