import { useEffect, useMemo, useState } from "react";

import { SearchInCircleSvg, SearchSvg } from "utils/icons";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { flagImages, flags } from "utils/consts";

interface IProps {
  className?: string,
  country: string,
  onChange?: (country: string) => void,
}

export const SelectCountry: React.FC<IProps> = (props) => {
  const { className, country, onChange } = props;
  const { countries } = useSelector((root: RootState) => root.country);
  const [filter, setFilter] = useState<string>("");
  const [loadedFlags, setLoadedFlags] = useState<{[key: string]: boolean}>({});

  useEffect(() => {
    const preloadFlags = async () => {
      const flagPromises = countries.map((country) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.onload = () => {
            setLoadedFlags(prev => ({...prev, [country.code]: true}));
            resolve(true);
          };
          img.src = flagImages[country.code];
        });
      });

      await Promise.all(flagPromises);
    };

    preloadFlags();
  }, [countries]);

  const onChangeFilter = (e: any) => {
    setFilter(e.target.value);
  };

  const onChangeCountry = (country: string) => {
    onChange && onChange(country);
  };

  const _countries = useMemo(() => {
    return countries.filter(country => country.name.toLowerCase().includes(filter.toLowerCase()));
  }, [filter])

  return (
    <>
      <div className={`relative flex w-full ${className}`}>
        <img className="absolute left-4 top-4" src={SearchSvg} />
        <input
          className="block p-4 pl-12 w-full text-base font-medium text-font-primary bg-surface-secondary rounded-xl border border-surface-secondary outline-none focus:ring-primary focus:border-primary duration-200"
          onChange={onChangeFilter}
        />
      </div>
      <div className="overflow-auto flex-grow">
        {
          _countries.length ? _countries.map((el: any, index: number) => {
            return (
              <div
                key={index}
                className={`flex items-center mt-4 p-2 ${el.code === country && 'bg-feedback-information-tint'}`}
                onClick={() => { onChangeCountry(el.code); }}
              >
                {loadedFlags[el.code] && (  // Only show flag when loaded
                  <img className="w-8 h-6" src={flagImages[el.code]} />
                )}
                <div className="text-base font-medium text-font-primary ml-4">{el.name}</div>
              </div>
            );
          })
            : filter && (
              <div className="flex flex-col justify-content items-center p-12">
                <img src={SearchInCircleSvg} />
                <span className="text-base font-medium text-font-primary mt-4">We do not support this currency yet!</span>
              </div>
            )
        }
      </div>
    </>
  );
};